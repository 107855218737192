<template>
  <div style="min-height: 400px;">
    <div class="control" style="display:flex;align-items: center;">
    <label class="label" style="margin-bottom:0;display:block;margin-right:15px;">Add your number phone</label>
      <v-text-field
        outlined
        hide-details
        dense
        :placeholder="$t('constructor[\'Phone\']')"
        v-model="content.content"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "typePhone",
  props:['content'],
};
</script>

<style lang="scss" scoped></style>
